import React from 'react';

import Layout from '../components/Layout';
import {MicroCard, ImageCard, MiniCard} from '../components/Card';

import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';
import MicrosoftLogo from '../assets/images/ms-partner-logo-gr.png';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import D365Logo from '../assets/images/D365-logo-gr.png';
import RightImage from '../assets/images/icon-right.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const PublicSectorPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h3 >Public Sector Services</h3>
                        <h1 style={{letterSpacing:'.1em'}}>
                            Enforce compliance, Integrate processes and workforce. 
                            Analyze and share intelligence.
                        </h1>
                </header>
        </div>  
    
        <div className="inner row" style={{margin:'.25em auto'}}>
            <div className='col-9 col-12-medium' style={{marginTop:'10px'}}>
                <span className="image left">
                    <img src={PublicSectorImage} alt="Public Sector" />
                </span>
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                Public Sector entities around the world are embarking on eGovernance, 
                enablement of information exchange and provide access to services for the 
                consumers electronically. Some of the public facing implmentations include Tax payment systems,
                business registrations, e-health, e-procurement, electronic payment and more.
                <br /> <br />
                As technology evolves, consumers demand more convenient access to 
                public services. The consumers expect the agility and efficiency found
                in the private sector.
                <br /> <br />
                Our solutions provide a comprehensive approach to engage the consumers for 
                better governance. We leverage our expertise and enable organizations
                to migrate from the Old Legacy Systems seamlessly and 
                implement disruptive Scalable Digital Solutions. 
                <br /><br />
                We consolidate innovative ideas and travel the path with you
                in transforming your vision to reality. 
                </p>
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    We specialize in the implementation of
                </p>
                <div className="row" style={{display:'flex',  flexWrap:'wrap', margin:'.25em auto'}}>
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Compliance & Standards" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Process Automation" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Inter department collaboration" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Flexible & efficient ecosystem" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Data driven analytics" />
                    <MiniCard className='col-6' cardImage={RightImage} cardTitle="Integrate with existing solutions" />
                    
                </div>
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>and nurture</p>
                <div className="row" style={{fontSize:'90%', display:'flex', flexDirection:'column',flexWrap:'wrap'}}>
                    <li>Fresh thinking</li>
                    <li>Innovative technologies</li>
                    <li>Ensure flexibility to adapt and </li>
                    <li>Innovation culture to stay ahead</li>
                </div>
            </div>
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
                <MicroCard className='col-6 col-12-small' 
                            cardTitle="Transformation" cardImage={TransformImage} 
                            cardLink='/transform-your-business'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Solutions on the cloud" cardImage={SolutionsImage} 
                        cardLink='/business-solutions-on-the-cloud'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Talent Services" cardImage={TalentImage} 
                        cardLink='/talent-services'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                    cardLink='/lowcode-nocode'/>
                <MicroCard className='col-6 col-12-small' 
                    cardTitle="Modernize" cardImage={ModernizeImage} 
                    cardLink='/modernize-solutions'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Enable the Cloud" cardImage={CloudImage} 
                        cardLink='/enable-the-cloud'/>
                
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Technology Partners</p>
                <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
                <ImageCard className='col-12 col-6-medium' cardImage={MicrosoftLogo} />   
                <ImageCard className='col-12 col-6-medium' cardImage={D365Logo} height='35px'/>     
                
            </div>
        </div>
    </div>
  </Layout>
);

export default PublicSectorPage;
